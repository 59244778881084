import React from 'react';

const TermsAndConditions = () => {
    return (
        <div>
            <h1>Terms and Conditions </h1>
        </div>
    );
};

export default TermsAndConditions;
